.ck-editor__editable_inline {
    min-height: 300px;
    max-height: 400px;
}

.ck-content .image-inline {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.image_resized {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/*.image_resized {*/
/*    display: block !important;*/
/*    margin: 0 auto !important;*/
/*}*/

@media screen and (max-width: 1000px) {
    .ck-editor__editable_inline {
        min-height: 30vh !important;
        max-height: 30vh !important;
    }
}
